import { compose, defaultProps, withProps } from 'core/libs/recompose';

import bindProps from 'core/components/bindProps';

import Ad from 'core/components/Ad';
import AdBillboard from 'core/components/Ad/Billboard';

import sponsoredStyles from 'core/components/Ad/styles/sponsoredStyles';

import card4Styles from './styles/card4';
import card2Styles from './styles/card2';
import card3Type2Styles from './styles/card3type2';

export const Billboard = bindProps({
  name: 'Billboard',
  height: 250,
  blockId: '435056028',
  options: {
    p1: 'brzmq',
    p2: 'y',
    pct: 'a',
  },
})(AdBillboard);

export const Background = bindProps({
  name: 'Background',
  blockId: '435055994',
  options: {
    p1: 'bjijb',
    p2: 'enhl',
    pct: 'a',
  },
})(Ad);

export const Fullscreen = bindProps({
  name: 'Fullscreen',
  blockId: '435056000',
  options: {
    'p1': 'brmgm',
    'p2': 'emiu',
    'pct': 'a',
  },
})(Ad);

export const Shtorka = bindProps({
  name: 'Shtorka',
  blockId: '435056084',
  options: {
    p1: 'bsgde',
    p2: 'exne',
    pct: 'a',
  },
})(Ad);

export const Ad240x400 = bindProps({
  name: '240x400',
  width: 300,
  height: 600,
  blockId: '435056064',
  stickyTemp: 3,
  options: {
    p1: 'bitam',
    p2: 'emhk',
    pct: 'a',
  },
})(Ad);

export const Ad240x400Second = bindProps({
  name: '240x400_2nd',
  width: 300,
  height: 600,
  lazy: true,
  blockId: '435056116',
  options: {
    p1: 'bqvht',
    p2: 'ewqs',
    pct: 'a',
  },
})(Ad);

export const Ad240x200 = bindProps({
  name: '240x200',
  width: 300,
  lazy: true,
  blockId: '436406096',
  options: {
    p1: 'brsqi',
    p2: 'ewzd',
    pct: 'a',
  },
})(Ad);

export const Context = compose(
  bindProps({
    name: 'Context',
    height: 80,
    lazy: true,
    blockId: '436406108',
    options: {
      p1: 'buomp',
      p2: 'ewzc',
      pct: 'a',
    },
  }),
  defaultProps({ count: 1 }),
  withProps(({ count }) => ({ manualPuids: { puid44: `context_item${count}` } })),
)(Ad);

export const Sponsored = compose(
  bindProps({
    name: 'Sponsored',
    blockId: '462307650',
    options: {
      'p1': 'bxynt',
      'p2': 'fomx',
      'pct': 'c',
    },
  }),
  sponsoredStyles,
)(Ad);

export const SuperFooter = bindProps({
  name: '100%x240',
  width: 600,
  height: 240,
  lazy: true,
  blockId: '435056128',
  options: {
    p1: 'brzmt',
    p2: 'fcuz',
    pct: 'a',
  },
})(Ad);

export const Parallax = bindProps({
  name: 'Parallax',
  width: 1280,
  height: 250,
  lazy: true,
  blockId: '462307648',
  options: {
    'p1': 'bxyns',
    'p2': 'fhoe',
    'pct': 'a',
  },
})(Ad);

export const AdCenter = bindProps({
  name: 'Center',
  width: 600,
  height: 240,
  lazy: true,
  blockId: '462307642',
  options: {
    'p1': 'bxynq',
    'p2': 'fomw',
    'pct': 'a',
  },
})(Ad);

export const InRead = bindProps({
  name: 'inRead',
  height: 300,
  blockId: '435056114',
  options: {
    p1: 'brzmv',
    p2: 'fcvb',
    pct: 'a',
  },
})(Ad);

export const Native1 = compose(
  bindProps({
    name: 'Native1',
    blockId: '436406102',
    options: {
      p1: 'buomn',
      p2: 'fhzr',
      pct: 'a',
    },
  }),
  card2Styles
)(Ad);

export const Native1Rubric = compose(
  bindProps({
    name: 'Native1 rubric',
    blockId: '436406102',
    options: {
      p1: 'buomn',
      p2: 'fhzr',
      pct: 'a',
    },
  }),
  card4Styles
)(Ad);

export const Native2 = compose(
  bindProps({
    name: 'Native2',
    blockId: '436406104',
    lazy: true,
    options: {
      p1: 'buomo',
      p2: 'fhzs',
      pct: 'a',
    },
  }),
  card2Styles
)(Ad);

export const Native2Rubric = compose(
  bindProps({
    name: 'Native2',
    blockId: '436406104',
    lazy: true,
    options: {
      p1: 'buomo',
      p2: 'fhzs',
      pct: 'a',
    },
  }),
  card4Styles
)(Ad);

export const Native3 = compose(
  bindProps({
    name: 'Native3',
    width: 300,
    blockId: '462307654',
    lazy: true,
    options: {
      'p1': 'bxynr',
      'p2': 'fjgk',
      'pct': 'a',
    },
  }),
  card3Type2Styles
)(Ad);

export const NativeFooter = compose(
  bindProps({
    name: 'NativeFooter',
    lazy: true,
    blockId: '436406106',
    options: {
      p1: 'buomq',
      p2: 'fhzt',
      pct: 'a',
    },
  }),
  card4Styles
)(Ad);

export const InGallery = bindProps({
  name: 'InGallery',
  blockId: '518436581',
  disableAutoInit: true,
})(Ad);
