import PropTypes from 'prop-types';
import { withRouter } from 'core/libs/router';
import queryString from 'core/libs/query-string';

import TopicHeaderTexts from 'core/components/TopicHeaderTexts';
import { Desktop } from 'core/components/breakpoint';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import SocialShare from 'site/components/SocialShare';
import { VerticalIndent } from 'site/components/Indents';

import TopicHeaderMedia from '../TopicHeaderMedia';
import TopicTopline from 'core/components/TopicTopline';

import styles from './index.styl';

function TopicHeaderPremium(props) {
  const {
    content,
    infinityIndex,
    location: {
      search,
    },
  } = props;

  const {
    attributes: {
      link,
      is_comments_disabled: isCommentsDisabled,
    },
  } = content;

  const page = infinityIndex > 0
    ? 1
    : +queryString.parse(search).page || 1;

  return (
    <div className={styles.premium}>
      <div className={styles.top}>
        <TopicTopline center />
        <VerticalIndent top={15} />
        <TopicHeaderTexts />
        <Desktop>
          <SocialShare
            xid={link}
            isCommentsDisabled={isCommentsDisabled}
          />
        </Desktop>
      </div>
      <VerticalIndent />
      {page === 1 && (
        <div className={styles.media}>
          <TopicHeaderMedia content={content} />
        </div>
      )}
    </div>
  );
}

TopicHeaderPremium.propTypes = {
  content: modelPropTypes(topicAttributes).isRequired,
  location: PropTypes.object.isRequired,
  infinityIndex: PropTypes.number,
};

export default withRouter(TopicHeaderPremium);
