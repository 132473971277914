import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Button from 'core/components/Button';
import cardHOC from 'core/components/card';
import Link from 'core/components/Link';
import Image from 'core/components/Image';
import MarkdownWrapper from 'core/components/MarkdownWrapper';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import resolveRelationships from 'core/utils/relationships';

import styles from './index.styl';
import boy from './boy_2x.png';
import girl from './girl_2x.png';

const kidsImages = { boy, girl };

const requiredPayloadFields = [
  'link',
  'headline',
  'list_headline',
];

const relationships = resolveRelationships(['content'], {});

function CardKidsQuestions({ content, kidGender, theme }) {
  if (!content) return null;

  const {
    attributes: {
      link,
      headline,
      list_headline: listHeadline,
    },
  } = content;

  const kidImage = kidsImages[kidGender || (Math.random() < 0.5 ? 'boy' : 'girl')];
  /** Текст для белого блока карточки выбирается из первого текстового виджета топика */
  const markdowns = relationships(content).content.widgets.filter(({ type }) => type === 'markdown');
  const cardText = markdowns.length > 0 ? markdowns[0].attributes.body : '';

  return (
    <Link to={link} type='secondary'>
      <style jsx>{`
        .${styles.content}:after
          transition opacity ${theme.animations.hover}
      `}</style>
      <div className={styles.content}>
        <div className={styles.question}>ВОПРОС ДНЯ ОТ РЕБЕНКА</div>
        <div className={styles.questionTitle}>{listHeadline || headline}</div>
        <div className={styles.kid}>
          <Image
            src={{ rel_url: kidImage }}
            width={157}
            height={149}
          />
        </div>
        <div className={styles.cardsStack}>
          <div className={cx(styles.card, styles.card1)} />
          <div className={cx(styles.card, styles.card2)} />
          <div className={cx(styles.card, styles.card3)}>
            <MarkdownWrapper>{cardText}</MarkdownWrapper>
          </div>
          <div className={styles.gradient} />
          <div className={cx(styles.buttonContainer, 'button')}>
            <Button minWidth='100%'>Узнать ответ</Button>
          </div>
        </div>
      </div>
    </Link>
  );
}

CardKidsQuestions.propTypes = {
  content: modelPropTypes(topicAttributes).isRequired,
  kidGender: PropTypes.string,
  theme: PropTypes.object,
};

const Card = cardHOC(CardKidsQuestions);

Card.requiredPayloadFields = requiredPayloadFields;

export default Card;
