import McdonaldsLogo from 'site/icons/McdonaldsLogo';

import styles from './index.styl';

export default function McDonaldsLabel() {
  return (
    <div className={styles.label}>
      <McdonaldsLogo width='22' height='19' />
    </div>
  );
}
