import PropTypes from 'prop-types';
import cx from 'classnames';

import withTheme from 'core/components/theme';

/**
 * Стили thin-button дублируются как глобальные для светлой темы.
 * Это сделано для того, чтобы редакция могла вставлять эту кнопку в тексте
 * в виде html разметки
 */
import './index.styl';

function ThinButton(props) {
  const {
    children,
    theme,
    inactive,
    disabled,
    onClick,
    type,
    marginRight,
  } = props;

  const color = props.color || theme.colors.active1;

  /**
   * Глобальные стили добавлены для того, чтобы была возможность
   * вставить стилизованную кнопку через снипет.
   */
  return (
    <div
      className={cx('thin-button', type, disabled && 'disabled')}
      onClick={inactive ? undefined : onClick}
    >
      <style jsx>{`
        .thin-button
          color ${color}
          border solid 1px ${color}
          margin-right ${marginRight}
          transition color ${theme.animations.hover},
                     background-color ${theme.animations.hover},
                     border-color ${theme.animations.hover}
          &:hover
            background-color ${color}
            color ${theme.colors.content}

          &.primary
            color ${theme.colors.content}
            background-color ${color}
            &:hover
              color ${color}
              background-color transparent
              border solid 1px ${color}

          &.ghost
            color ${theme.colors.primary}
            &:hover
              color ${theme.colors.primary}
              border solid 1px ${color}

          &.disabled
            border-color ${theme.colors.hint}
            color ${theme.colors.hint}
            &:hover
              border-color ${theme.colors.hint}
              color ${theme.colors.hint}
      `}
      </style>
      {children}
    </div>
  );
}

ThinButton.defaultProps = {
  type: 'secondary',
  marginRight: '0px',
};

ThinButton.propTypes = {
  type: PropTypes.string,
  state: PropTypes.string,
  inactive: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  color: PropTypes.string,
  marginRight: PropTypes.string,
  theme: PropTypes.object,
};

export default withTheme(ThinButton);
