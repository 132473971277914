import PropTypes from 'prop-types';

import Mjolnir from 'core/components/Mjolnir';
import bindProps from 'core/components/bindProps';

import Title5 from 'site/components/Title5';

import { IndentWrap } from 'site/components/Wrappers';

import { Card1Type1, Card1Type2 } from 'site/cards/Card1';
import Card2 from 'site/cards/Card2';


export default function MainVideo({ content, isDesktop }) {
  if (!content.length) return null;

  return (
    <div>
      <Title5>Лучшие видео</Title5>
      <IndentWrap bottom={20} />
      <Mjolnir
        heroCard={bindProps({ imageVersion: 'main' })(isDesktop ? Card1Type1 : Card1Type2)}
        card={Card2}
        content={content}
        interitemSpacing={20}
      />
    </div>
  );
}

MainVideo.propTypes = {
  content: PropTypes.array,
  isDesktop: PropTypes.bool,
};
