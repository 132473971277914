import PropTypes from 'prop-types';

import resolveRelationships from 'core/utils/relationships';
import { incut as coreIncut } from 'core/components/TopicContent/blocks';

import McDonaldsWidget from 'site/components/mcdonalds/McDonaldsWidget';
import Quote from 'site/icons/Quote';

import { DOBRYE_DELA_SLUG } from 'site/constants';
import styles from './index.styl';

const relationships = resolveRelationships(
  ['rubric'],
  {},
  { rubric: {} },
);

export default function Incut(props) {
  const {
    block,
    parentProps,
    ctx,
  } = props;

  const {
    topic: {
      content,
    },
  } = parentProps;

  const {
    rubric,
  } = relationships(content);

  if (rubric.slug === DOBRYE_DELA_SLUG) {
    return (
      <McDonaldsWidget>{block?.attributes?.body}</McDonaldsWidget>
    );
  }

  return (
    <div className={styles.incut}>
      <Quote
        width={39}
        height={30}
        className={styles.icon}
      />
      {coreIncut(block, parentProps, ctx)}
    </div>
  );
}

Incut.propTypes = {
  block: PropTypes.object,
  parentProps: PropTypes.object,
  ctx: PropTypes.object,
};
