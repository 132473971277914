import cx from 'classnames';
import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import breakpointPropTypes from 'core/utils/prop-types/breakpoint';


function TitleCardL(props) {
  const {
    theme,
    breakpoint,
    children,
  } = props;

  return (
    <div className={cx('title-m', breakpoint)}>
      {children}

      <style jsx>{`
        .title-m
          font-family ${theme.fonts.display}
          color ${theme.colors.primary}
          transition color ${theme.animations.hover}
          :global(a):hover &
            color ${theme.colors.active1}
      `}
      </style>
      <style jsx>{`
        .title-m
          font-size 18px
          line-height 1.22
          font-weight 500
          &.mobile
            font-size 16px
            line-height 1.4
            @media (min-width: 600px)
              font-size 20px
      `}
      </style>
    </div>
  );
}

TitleCardL.propTypes = {
  breakpoint: breakpointPropTypes(),
  theme: PropTypes.object,
};

export default withBreakpoint(withTheme(TitleCardL));
