import React from 'react';
import PropTypes from 'prop-types';

import LogoPng from 'site/icons/Logo.png';

import styles from './index.styl';

export default function Logo(props, { amp, host }) {
  const {
    isFooter,
    width,
    height,
  } = props;

  const TagName = amp ? 'amp-img' : 'img';

  const imageAttributes = {
    src: LogoPng,
    width,
    height,
    alt: host,
    // делаем выравнивание относительно меню в шапке
    className: !isFooter && !amp ? styles.shiftedLogo : null,
  };

  return (
    <TagName {...imageAttributes} />
  );
}

Logo.contextTypes = {
  amp: PropTypes.bool,
  host: PropTypes.string,
};

Logo.propTypes = {
  isFooter: PropTypes.bool,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};
