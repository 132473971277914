import PropTypes from 'prop-types';

function IndentWrap(props) {
  const {
    children,
    top,
    bottom,
    left,
    right,
  } = props;

  return (
    <div
      style={{
        ...top && { marginTop: `${top}px` },
        ...bottom && { marginBottom: `${bottom}px` },
        ...left && { marginLeft: `${left}px` },
        ...right && { marginRight: `${right}px` },
      }}
    >
      {children}
    </div>
  );
}

IndentWrap.propTypes = {
  top: PropTypes.number,
  bottom: PropTypes.number,
  left: PropTypes.number,
  right: PropTypes.number,
};

export default IndentWrap;
