import { filterRequiredParams } from 'core/utils/api';

import { topicsFetcher } from 'core/fetchers/topics';

import resolve from 'core/resolver/resolve';

import Card1 from 'site/cards/Card1';
import Card2 from 'site/cards/Card2';
import CardExpert from 'site/cards/CardExpert';
import CardMom from 'site/cards/CardMom';
import CardPhoto from 'site/cards/CardPhoto';

import mediaFetcher from 'site/fetchers/media';

import { DOSUG_LIMIT } from 'site/constants';


export default resolve({
  firstBlockTopics: topicsFetcher({
    card: Card2,
    limit: 7,
    topic_type: 'news',
    visibility: 'main_page',
    renderError: () => [],
  }),

  mainArticles: mediaFetcher({
    include: 'image,rubric',
    fields: filterRequiredParams([Card1, Card2], 'fields'),
    list: 'main',
    topic_type: 'article,video,gallery',
    limit: 7,
  }),

  personalExperienceTopics: topicsFetcher({
    card: CardExpert,
    limit: 8,
    tag: 'lichnyy-opyt',
    visibility: 'main_page',
    renderError: () => [],
  }),

  parents: topicsFetcher({
    card: CardMom,
    limit: 8,
    tag: 'roditeli-o-vazhnom',
    plugin: '{"opinion": 1}',
    visibility: 'main_page',
    renderError: () => [],
  }),

  photo: mediaFetcher({
    include: 'image,photo_gallery,rubric',
    fields: filterRequiredParams([Card2, CardPhoto], 'fields'),
    list: 'photo',
    topic_type: 'gallery',
  }),

  video: mediaFetcher({
    include: 'image,rubric',
    fields: filterRequiredParams([Card1, Card2], 'fields'),
    list: 'video',
    topic_type: 'video',
  }),

  experts: topicsFetcher({
    card: CardExpert,
    limit: 8,
    tag: 'mneniye-ekspertov',
    visibility: 'main_page',
    renderError: () => [],
  }),

  dosug: topicsFetcher({
    limit: DOSUG_LIMIT,
    fields: filterRequiredParams([Card1, Card2], 'fields'),
    include: filterRequiredParams([Card1, Card2], 'include'),
    tag: 'igry',
    visibility: 'main_page',
    renderError: () => [],
  }),
});
