import PropTypes from 'prop-types';

import SelfPromo from 'core/components/SelfPromo';
import StickyPortal from 'core/components/StickyPortal';
import Bouesque from 'core/components/Bouesque';
import { YandexMetrikaGoalSender } from 'core/components/MetricsEventSender';

import { IndentWrap } from 'site/components/Wrappers';
import { PartnersVertical } from 'site/components/Partners';
import KidsQuestions from 'site/components/KidsQuestions';

import { KIDS_QUESTIONS_SLUG } from 'site/constants';

import {
  Ad240x400,
  Ad240x200,
  Ad240x400Second,
} from 'site/components/Ads/desktop';

import AdWrapper from 'core/components/Ad/AdWrapper';

export default function InnerSideColumn(props) {
  const {
    show240x200,
    showBoesque,
    rubricSlug,
  } = props;

  const indent = 36;

  return (
    <>
      {showBoesque && (
        <IndentWrap bottom={indent}>
          <Bouesque />
        </IndentWrap>
      )}
      <StickyPortal listenStickyRemoveEvent>
        <AdWrapper bottom={indent}>
          <Ad240x400 />
        </AdWrapper>
        {rubricSlug !== KIDS_QUESTIONS_SLUG && (
          <YandexMetrikaGoalSender
            show='child_questions_desktop_sidebar_show'
            click='child_questions_desktop_sidebar_click'
            skipTargetCheck
            checkScrollY
          >
            <KidsQuestions indent={{ bottom: indent }} />
          </YandexMetrikaGoalSender>
        )}
        <SelfPromo />
        <IndentWrap bottom={indent} />
      </StickyPortal>
      <StickyPortal maxOffset={2400}>
        <AdWrapper bottom={indent}><Ad240x400Second /></AdWrapper>
        <IndentWrap top={indent} />
        <PartnersVertical />
        {show240x200 && <AdWrapper top={indent}><Ad240x200 /></AdWrapper>}
      </StickyPortal>
    </>
  );
}

InnerSideColumn.defaultProps = {
  showBoesque: true,
};

InnerSideColumn.propTypes = {
  showBoesque: PropTypes.bool,
  show240x200: PropTypes.bool,
  rubricSlug: PropTypes.string,
};
