import PropTypes from 'prop-types';

import topicProvider from 'core/resolver/data/topic';

import Topic from 'core/components/Topic';
import Link from 'core/components/Link';
import Button from 'core/components/Button';
import Comments from 'core/components/Comments';

import withPageHocs from 'core/components/withPageHocs';

import TopicWrapper from 'site/pages/topic/TopicWrapper';
import TopicHeader from 'site/pages/topic/TopicHeader';
import { IndentWrap } from 'site/components/Wrappers';


function CommentsPage({ content: topic }) {
  return (
    <Topic content={topic}>
      {content => {
        const {
          attributes: {
            link,
            is_comments_disabled: isCommentsDisabled,
          },
        } = content;

        return (
          <TopicWrapper content={content}>
            <IndentWrap bottom={40}>
              <TopicHeader content={content} comments />
              <Link
                to={link}
                type='secondary'
              >
                <Button size='small'>Вернуться к статье</Button>
              </Link>
            </IndentWrap>

            <Comments xid={link} isCommentsDisabled={isCommentsDisabled} />
          </TopicWrapper>
        );
      }}
    </Topic>
  );
}

CommentsPage.propTypes = {
  content: PropTypes.object,
};

export default withPageHocs(topicProvider)(CommentsPage);
