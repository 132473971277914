import PropTypes from 'prop-types';

import { withNonPureBreakpoint } from 'core/components/breakpoint';
import Shapka from 'core/components/Shapka';
import Additional from './Additional';

import menu from './menu';

function Shlyapa(props) {
  const {
    isMobile,
  } = props;

  return (
    <Shapka
      additional={<Additional />}
      {...isMobile && {
        additionalMenuItems: menu,
      }}
    />
  );
}

Shlyapa.propTypes = {
  isMobile: PropTypes.bool,
};

export default withNonPureBreakpoint(Shlyapa);
