import PropTypes from 'prop-types';

import { NegativeMobile } from 'site/components/Wrappers';

import { getRealType } from 'site/utils';

import TopicHeaderImage from './TopicHeaderImage';
import TopicHeaderGallery from './TopicHeaderGallery';
import TopicHeaderVideo from './TopicHeaderVideo';

const topicTypeMap = {
  news: TopicHeaderImage,
  article: TopicHeaderImage,
  gallery: TopicHeaderGallery,
  'news-gallery': TopicHeaderGallery,
  video: TopicHeaderVideo,
  horoscope: TopicHeaderImage,
};

export default function TopicHeaderMedia({ content }) {
  const HeaderContent = topicTypeMap[getRealType(content)] || TopicHeaderImage;

  return (
    <NegativeMobile>
      <HeaderContent content={content} />
    </NegativeMobile>
  );
}

TopicHeaderMedia.propTypes = {
  content: PropTypes.object.isRequired,
};
