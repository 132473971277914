import PropTypes from 'prop-types';
import coreMarkdown from 'core/components/TopicContent/blocks/markdown';

import styles from './index.styl';

export default function Markdown(props) {
  const {
    block,
    parentProps,
    ctx,
  } = props;

  const {
    theme,
  } = parentProps;

  return (
    <div className={styles.markdown}>
      <style jsx>{`
        .${styles.markdown}
          :global(blockquote)
            font italic 24px/1.2 ${theme.fonts.text}
      `}
      </style>
      {coreMarkdown(block, parentProps, ctx)}
    </div>
  );
}

Markdown.propTypes = {
  block: PropTypes.object,
  parentProps: PropTypes.object,
  ctx: PropTypes.object,
};
