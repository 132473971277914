import React from 'react';
import cx from 'classnames';
import withTheme from 'core/components/theme';

import GlobalStyles from '../global';
import styles from './index.styl';

export default function card4Styles(Component) {
  return withTheme(props => (
    <div className={cx(styles['adv-native_card-4'], 'adv-native adv-native_card-4')}>
      <GlobalStyles {...props} />

      <Component {...props} />
    </div>
  ));
}
