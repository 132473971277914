import PropTypes from 'prop-types';
import cx from 'classnames';
import { withRouter } from 'core/libs/router';

import withTheme from 'core/components/theme';

import Link from 'core/components/Link';

import styles from './index.styl';

const rubrics = [
  {
    title: 'Беременность и роды',
    slug: 'beremennost-i-rody',
  },
  {
    title: 'до 1 года',
    slug: 'do-1-goda',
  },
  {
    title: '1—3 года',
    slug: '1-3-goda-rannee-detstvo-yaselnyy-vozrast',
  },
  {
    title: '4—6 лет',
    slug: '4-6-let-doshkolnyy-vozrast',
  },
  {
    title: '7—10 лет',
    slug: '7-10-let-mladshiy-shkolnyy-vozrast',
  },
  {
    title: 'Подростки',
    slug: 'podrostki',
  },
];

function Rubricator(props) {
  const {
    theme,
    location: {
      pathname,
    },
  } = props;

  return (
    <div className={styles.rubricator}>
      <style jsx>{`
        .rubricator
          &__text
            color ${theme.colors.primary}
            font 500 18px/1.2 ${theme.fonts.text}
      `}
      </style>
      <div className={styles.rubricator__list}>
        {rubrics.map(item => {
          const link = `/age/${item.slug}`;
          const isActive = link === pathname;
          return (
            <div
              key={item.slug}
              className={cx(styles.rubricator__item, styles[`rubricator__item--${item.slug}`], isActive && 'active')}
            >
              <div className={styles.rubricator__content}>
                <Link
                  to={link}
                  className={styles.link}
                  type='secondary'
                >
                  <span className={cx(styles.rubricator__text, 'rubricator__text')}>
                    {item.title}
                  </span>
                </Link>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

Rubricator.propTypes = {
  theme: PropTypes.object,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
};

export default withRouter(withTheme(Rubricator));
