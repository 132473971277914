import PropTypes from 'prop-types';

import resolve from 'core/resolver/resolve';

import { rawContentFetcher } from 'core/fetchers/searchPage';

import withPageHocs from 'core/components/withPageHocs';
import SearchPage from 'core/components/SearchPage';
import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent } from 'core/components/Wrappers';

import InnerSideColumn from 'site/components/InnerSideColumn';
import Boroda from 'site/components/Boroda';

import {
  Context,
  SuperFooter,
} from 'site/components/Ads/desktop';

import {
  Listing1,
  ListingSpecSearch,
} from 'site/components/Ads/mobile';

import CardSearch from 'site/cards/CardSearch';

import filters from './filters';


function Search({ rawContent }) {
  return (
    <PageIndent>
      <ColumnLayout rightColumn={<InnerSideColumn showBoesque={false} />}>
        <SearchPage
          rawContent={rawContent}
          filters={filters}
          ad1={SuperFooter}
          ad2={Context}
          ad1Mobile={Listing1}
          ad2Mobile={ListingSpecSearch}
          card={CardSearch}
        />
        <Boroda showRecommender showPopular />
      </ColumnLayout>
    </PageIndent>
  );
}

Search.propTypes = {
  rawContent: PropTypes.array,
};

const dataProvider = resolve({
  rawContent: rawContentFetcher({ card: CardSearch }),
});

export default withPageHocs(dataProvider)(Search);
