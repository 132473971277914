const data = [
  '/zdorove/1-y-mesyac-beremennosti.htm',
  '/zdorove/2-y-mesyac-beremennosti.htm',
  '/zdorove/3-y-mesyac-beremennosti.htm',
  '/zdorove/4-y-mesyac-beremennosti.htm',
  '/zdorove/5-y-mesyac-beremennosti.htm',
  '/zdorove/6-y-mesyac-beremennosti.htm',
  '/zdorove/7-y-mesyac-beremennosti.htm',
  '/zdorove/8-y-mesyac-beremennosti.htm',
  '/zdorove/9-y-mesyac-beremennosti.htm',
];

export default data.map((item, index) => {
  return {
    id: `${index}`,
    type: 'article',
    attributes: {
      topic_type: 'article',
      headline: '',
      published_at: '2017-11-20T12:57:00.000Z',
      link: item,
      announce: 'item.announce',
    },
  };
});
