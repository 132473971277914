import PropTypes from 'prop-types';
import cx from 'classnames';

import withTheme from 'core/components/theme';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import Link from 'core/components/Link';
import styles from './index.styl';

function Title5(props) {
  const {
    children,
    theme,
    line,
    isDesktop,
    link,
  } = props;

  return (
    <div className={cx(styles.title5, line && isDesktop && [styles.line])}>
      <style jsx>{`
        .${styles.title5}
          font bold 12px/1.6 ${theme.fonts.text}
        .${styles.line}::after
          background-color ${theme.colors.active1}
      `}
      </style>
      {link ? <Link to={link} type='secondary'>{children}</Link> : children}
    </div>
  );
}

Title5.propTypes = {
  line: PropTypes.bool,
  isDesktop: PropTypes.bool,
  link: PropTypes.string,
  theme: PropTypes.object,
};

export default withBreakpoint(withTheme(Title5));
