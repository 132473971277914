import { get } from 'core/libs/lodash';

import resolveRelationships from 'core/utils/relationships';
import { topicDateFormat } from 'core/utils/dates';

const relationships = resolveRelationships(['content'], {});

/**
 * Строка с большой буквы
 */
export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Проверяет существование переобложки.
 * @param {object} cover - переобложка из поля топика `image.versions`.
 * @param {number} cover.width - ширина переобложки.
 * @param {number} cover.height - высота переобложки.
 * @param {string} cover.rel_url - относительный URL переобложки.
 */

export function imageVersionExists(cover) {
  if (cover && cover.width && cover.height && cover.rel_url) return true;
  return false;
}

/**
 * Формирует ссылку на рубрику
 */
export function buildRubricUrl(slug, rootSlug) {
  if ((slug === rootSlug) || !rootSlug) {
    return `/${slug}`;
  }
  return `/${rootSlug}/${slug}`;
}

/**
 * Формат даты для карточек
 */
export function cardDateFormat(published) {
  return topicDateFormat(published);
}

/**
 * Новость или статья, у которой первый виджет фотогалерея,
 * должен быть фотогалереей
 */
export function getRealType(content) {
  const {
    attributes: {
      topic_type: topicType,
    },
  } = content;

  const {
    content: {
      widgets,
    },
  } = relationships(content);

  const isNewsOrArticle = topicType === 'news' || topicType === 'article';
  const isGalleryFirst = get(widgets, '[0].type') === 'photoGallery';

  return isNewsOrArticle && isGalleryFirst ? 'news-gallery' : topicType;
}

export const getMetrikaProps = (suffix, infinityIndex) => ({
  click: 'mobile_click' + suffix,
  show: ['mobile_show', infinityIndex + 1].join('') + suffix,
});
