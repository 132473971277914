import { denormalizeData } from 'core/utils/api';


export default (customParams) => (componentProps) => {
  const props = { ...componentProps, ...customParams };

  const {
    bebopApi,
    include,
    fields,
    list,
    topic_type: topicType,
    limit = 4,
  } = props;

  return bebopApi
    .getTopics({
      list,
      sort: 'list',
      limit,
      include,
      fields,
      visibility: 'main_page',
    })
    .then(denormalizeData)
    .then(listTopics => {
      if (listTopics.length >= limit) {
        return listTopics;
      }

      return bebopApi
        .getTopics({
          topic_type: topicType,
          limit: limit - listTopics.length,
          include,
          fields,
          visibility: 'main_page',
        })
        .then(denormalizeData)
        .then(allTopics => {
          return listTopics.concat(allTopics);
        });
    })
    .catch(() => []);
};
