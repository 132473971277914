import Lato from './lato/Lato-Regular.woff';
import LatoBold from './lato/Lato-Bold.woff';
import LatoItalic from './lato/Lato-Italic.woff';
import Lato2 from './lato/Lato-Regular.woff2';
import LatoBold2 from './lato/Lato-Bold.woff2';
import LatoItalic2 from './lato/Lato-Italic.woff2';
import LatoBlack from './lato/Lato-Black.woff';


const fonts = {
  text: 'Lato, Lato-fallback, Arial, sans-serif',
  get display() {
    return this.text;
  },
  get nuance() {
    return this.text;
  },
  faces: [
    {
      fontFamily: 'Lato',
      src: `local('Lato'), url(${Lato2}) format('woff2'), url(${Lato}) format('woff')`,
    },
    {
      fontFamily: 'Lato',
      fontWeight: 'bold',
      src: `local('Lato Bold'), url(${LatoBold2}) format('woff2'), url(${LatoBold}) format('woff')`,
    },
    {
      fontFamily: 'Lato',
      fontStyle: 'italic',
      src: `local('Lato Italic'), url(${LatoItalic2}) format('woff2'), url(${LatoItalic}) format('woff')`,
    },
    {
      fontFamily: 'Lato',
      fontWeight: '900',
      src: `url(${LatoBlack}) format('woff')`,
    },
    {
      fontFamily: 'Lato-fallback',
      sizeAdjust: '97.38%',
      ascentOverride: '98%',
      src: 'local("Arial")',
    },
  ],
};

export default fonts;
