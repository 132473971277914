import PropTypes from 'prop-types';

import { rawAuthorsFetcher } from 'core/fetchers/authorsPage';

import resolve from 'core/resolver/resolve';

import H1 from 'core/components/H1';
import AuthorsPage from 'core/components/AuthorsPage';
import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent, Indent } from 'core/components/Wrappers';
import withPageHocs from 'core/components/withPageHocs';

import Boroda from 'site/components/Boroda';
import InnerSideColumn from 'site/components/InnerSideColumn';


function Authors({ rawAuthors }) {
  return (
    <PageIndent>
      <ColumnLayout rightColumn={<InnerSideColumn />}>
        <H1>Авторы</H1>
        <Indent top={20} />
        <AuthorsPage rawAuthors={rawAuthors} />
        <Boroda showRecommender showPopular />
      </ColumnLayout>
    </PageIndent>
  );
}

Authors.propTypes = {
  rawAuthors: PropTypes.object,
};

const dataProvider = resolve({
  rawAuthors: rawAuthorsFetcher(),
});

export default withPageHocs(dataProvider)(Authors);
