import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { rawTopicsFetcher } from 'core/fetchers/listPage';

import resolve from 'core/resolver/resolve';

import ListPage from 'core/components/ListPage';
import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent, Indent } from 'core/components/Wrappers';
import withPageHocs from 'core/components/withPageHocs';

import { filterRequiredParams } from 'core/utils/api';

import InnerSideColumn from 'site/components/InnerSideColumn';
import RubricTopics from 'site/components/RubricTopics';
import RubricTitle from 'site/components/RubricTitle';
import Boroda from 'site/components/Boroda';
import Card1 from 'site/cards/Card1';
import Card4 from 'site/cards/Card4';

import FooterAds from 'site/components/Ads/FooterAds';

const title = 'Статьи';
const description = 'Все статьи Летидора для родителей. Советы экспертов, статьи лучших авторов, лайфхаки и многое другое.';

const fields = filterRequiredParams([Card1, Card4], 'fields');

function ArticlesPage({ rawData }) {
  return (
    <PageIndent>
      <ColumnLayout rightColumn={<InnerSideColumn />}>
        <ListPage
          title={title}
          description={description}
          rawData={rawData}
        >
          {({ content }) => (
            <Fragment>
              <RubricTitle title={title} />
              <Indent bottom={40} />
              <RubricTopics topics={content} />
            </Fragment>
          )}
        </ListPage>
        <Boroda showRecommender showPopular />
      </ColumnLayout>
      <FooterAds contextCount={3} />
    </PageIndent>
  );
}

ArticlesPage.propTypes = {
  rawData: PropTypes.object,
};

const dataProvider = resolve({
  rawData: rawTopicsFetcher({
    limit: 18,
    include: 'image',
    fields,
    topic_type: 'article,video,gallery',
  }),
});

export default withPageHocs(dataProvider)(ArticlesPage);
