import PropTypes from 'prop-types';

import Drum from 'core/components/Drum';
import Feed from 'core/components/Feed';

import Title5 from 'site/components/Title5';

import { IndentWrap } from 'site/components/Wrappers';

import CardPregnancy from 'site/cards/CardPregnancy';

import data from './data';

export default function MainPregnancy({ isDesktop }) {
  const Presenter = isDesktop ? Feed : Drum;

  return (
    <div>
      <Title5>Календарь беременности</Title5>
      <IndentWrap bottom={20} />
      <Presenter
        content={data}
        card={CardPregnancy}
        columns={9}
        limit={9}
        interitemSpacing={5}
        spaceBetween={10}
        itemWidth={260}
        itemWidthMobile={260}
      />
    </div>
  );
}

MainPregnancy.propTypes = {
  isDesktop: PropTypes.bool,
};
