import PropTypes from 'prop-types';

import Link from 'core/components/Link';
import SmartImage from 'core/components/SmartImage';

import styles from './index.styl';

export default function Card(props) {
  const {
    content: {
      link,
      logo,
      width,
      height,
    },
  } = props;

  return (
    <div className={styles.card}>
      <Link to={link}>
        <SmartImage
          url={logo}
          width={width}
          height={height}
        />
      </Link>
    </div>
  );
}

Card.propTypes = {
  content: PropTypes.shape({
    link: PropTypes.string,
    logo: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
  }),
};
