import PropTypes from 'prop-types';

import {
  tagFetcher,
  topicsFetcher,
} from 'core/fetchers/tagTopics';

import resolve from 'core/resolver/resolve';

import withPageHocs from 'core/components/withPageHocs';
import withTheme from 'core/components/theme';
import breakpointPropTypes from 'core/utils/prop-types/breakpoint';

import TagTopics from 'core/components/TagTopics';
import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent } from 'core/components/Wrappers';

import { filterRequiredParams } from 'core/utils/api';

import InnerSideColumn from 'site/components/InnerSideColumn';
import Boroda from 'site/components/Boroda';
import RubricTopics from 'site/components/RubricTopics';
import FooterAds from 'site/components/Ads/FooterAds';
import Card1 from 'site/cards/Card1';
import Card4 from 'site/cards/Card4';

import styles from './index.styl';


const limit = 18;

function TagPage({ tag, tagTopics, theme }) {
  return (
    <PageIndent>
      <ColumnLayout rightColumn={<InnerSideColumn />}>
        <TagTopics
          tag={tag}
          topics={tagTopics}
          titleComponent={({ children }) => (
            <h1 className={styles.title}>
              <style jsx>{`
                .${styles.title}
                  border-color ${theme.colors.active1}
              `}
              </style>
              {children}
            </h1>
          )}
          limit={limit}
        >
          {({ topics }) => {
            return  <RubricTopics topics={topics} notHero />;
          }}
        </TagTopics>
        <Boroda showRecommender showPopular />
      </ColumnLayout>
      <FooterAds contextCount={3} />
    </PageIndent>
  );
}

TagPage.propTypes = {
  tag: PropTypes.object,
  tagTopics: PropTypes.object,
  breakpoint: breakpointPropTypes(),
  theme: PropTypes.object,
};

const dataProvider = resolve({
  tag: tagFetcher(),
  tagTopics: topicsFetcher({
    limit,
    fields: filterRequiredParams([Card1, Card4], 'fields'),
    include: 'image',
  }),
});

export default withPageHocs(dataProvider)(withTheme(TagPage));
