import PropTypes from 'prop-types';
import { Fragment } from 'react';

import withTopic from 'core/components/Topic/withTopic';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import EmptyWrapper from 'core/components/EmptyWrapper';
import { YandexMetrikaGoalSender } from 'core/components/MetricsEventSender';

import { IndentWrap } from 'site/components/Wrappers';
import { getMetrikaProps } from 'site/utils';
import { PartnersHorizontal } from 'site/components/Partners';
import Recommender from 'site/components/Recommender';

import {
  SuperFooter,
} from 'site/components/Ads/desktop';
import AdWrapper from 'core/components/Ad/AdWrapper';


function TopicFooterBottom(props) {
  const {
    topic: {
      infinityIndex,
    },
    isDesktop,
    isMobile,
  } = props;

  const recommenderMetricksProps = getMetrikaProps('_readerschoice', infinityIndex);

  const MetrikaWrapper = isMobile && infinityIndex <= 1
    ? YandexMetrikaGoalSender
    : EmptyWrapper;

  return (
    <Fragment>
      {isDesktop && (
        <AdWrapper bottom={40}>
          <SuperFooter />
        </AdWrapper>
      )}
      <Fragment>
        {(infinityIndex % 2) !== 0
          ? (
            <MetrikaWrapper {...recommenderMetricksProps} checkScrollY>
              <Recommender />
            </MetrikaWrapper>
          ) : (
            <PartnersHorizontal />
          )
        }
        <IndentWrap bottom={40} />
      </Fragment>
    </Fragment>
  );
}

TopicFooterBottom.propTypes = {
  isDesktop: PropTypes.bool,
  isMobile: PropTypes.bool,
  topic: PropTypes.shape({
    content: modelPropTypes(topicAttributes).isRequired,
    infinityIndex: PropTypes.number,
  }),
};

export default withBreakpoint(withTopic(TopicFooterBottom));
