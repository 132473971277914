import color from 'core/libs/color';

const colors = {
  layout: '#fff',
  content: '#262626',
  active1: '#fcc11b',
  get active2() {
    return this.hint;
  },
  accent: '#efe5d4',
  error: '#ff3e56',
  primary: '#fff',
  input: '#fff',
  link: '#1c8af0',
  divider: color('#fff').alpha(0.3).string(),
  get hint() {
    return color(this.primary).alpha(0.5).string();
  },
  get placeholder() {
    return color(this.primary).alpha(0.3).string();
  },
  theme: '#9620C2',
};

const link = {
  tertiary: {
    idle: {
      color: colors.hint,
    },

    hover: {
      color: colors.hint,
      decoration: 'underline',
    },

    active: {
      color: colors.hint,
    },
  },
};

const controls = {
  link,
};

export default {
  colors,
  controls,
};
