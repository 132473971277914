import PropTypes from 'prop-types';

import Image from 'core/components/Image';
import Link from 'core/components/Link';

import styles from './index.styl';

/**
 * @author Igor Tomkovich <i.tomkovich@rambler-co.ru>
 */
export default function PublicationLink(props) {
  const {
    block,
    parentProps: {
      theme,
    },
  } = props;

  return (
    <Link
      to={block.attributes.link}
      type='secondary'
    >
      <style jsx>{`
        .${styles.container}
          transition background-color ${theme.animations.hover}
          color ${theme.colors.primary}
        .bordered
          border-bottom 1px solid ${theme.colors.primary}
      `}</style>
      <div className={styles.container}>
        {block.attributes.image && (
          <div className={styles.imageContainer}>
            <Image
              src={block.attributes.image.attributes.versions.original}
              width={70}
              height={102}
            />
          </div>
        )}
        <div className={block.attributes.image ? styles.textBlockMargin : null}>
          <div className={styles.fromBook}>Из книги</div>
          <div className={styles.caption}>&#171;<span className='bordered'>{block.attributes.caption}</span>&#187;</div>
          <div className={styles.credits}>{block.attributes.credits}</div>
        </div>
      </div>
    </Link>
  );
}

PublicationLink.propTypes = {
  block: PropTypes.object,
  parentProps: PropTypes.object,
};
