import { Fragment } from 'react';
import PropTypes from 'prop-types';

import Feed from 'core/components/Feed';
import Button from 'core/components/Button';
import Link from 'core/components/Link';
import { Indent } from 'core/components/Wrappers';
import BreakpointSwitcher from 'site/components/BreakpointSwitcher';
import withProps from 'core/components/bindProps';
import replaceCardWithAd from 'core/components/Ad/hoc/replaceCardWithAd';

import { Card1Type2 } from 'site/cards/Card1';
import Card2 from 'site/cards/Card2';

import Title5 from 'site/components/Title5';

import { Native1 } from 'site/components/Ads/desktop';
import { Listing1, ListingSpecNews } from 'site/components/Ads/mobile';
import AdWrapper from 'core/components/Ad/AdWrapper';

import styles from './index.styl';


const Card2Tipe3 = withProps({ type: 3 })(Card2);

export default function FirstBlock({ content, mainArticles, isDesktop }) {
  const indent = isDesktop ? 15 : 30;

  if (!content.length) return null;

  return (
    <div className={styles.firstBlock}>
      <div className={styles.glavnoe}>
        {mainArticles.length && (
          <Fragment>
            <Card1Type2 content={mainArticles[0]} highPriorityImage />
            <Indent bottom={indent} />
            <Feed
              grid
              card={Card2}
              content={mainArticles.slice(1)}
              columns={2}
              interitemSpacing={indent}
              cardsReplacement={{
                2: replaceCardWithAd(Native1, isDesktop),
              }}
            />
          </Fragment>
        )}
        <Link
          to='/articles'
          type='secondary'
          className={styles.link}
        >
          <Button type='secondary'>Все статьи</Button>
        </Link>
        <BreakpointSwitcher
          mobile={<AdWrapper top={20}><Listing1 /></AdWrapper>}
        />
      </div>
      <div className={styles.news}>
        <Feed
          content={content}
          title='Новости'
          headerComponent={Title5}
          card={Card2Tipe3}
          interitemSpacing={20}
        />
        <BreakpointSwitcher
          mobile={<AdWrapper top={20}><ListingSpecNews /></AdWrapper>}
        />
        <Link
          to='/news'
          type='secondary'
          className={styles.link}
        >
          <Button type='secondary'>Все новости</Button>
        </Link>
      </div>
    </div>
  );
}

FirstBlock.propTypes = {
  content: PropTypes.array,
  mainArticles: PropTypes.array,
  isDesktop: PropTypes.bool,
};
