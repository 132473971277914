import { compose, nest } from 'core/libs/recompose';

import bindProps from 'core/components/bindProps';

import Ad from 'core/components/Ad';

import sponsoredStyles from 'core/components/Ad/styles/sponsoredStyles';
import card3Type2Styles from './styles/card3type2';
import ListingSpecSearchNativeStyles from './styles/lisitngSpecSearch';
import ListingSpecRubricNativeStyles from './styles/listingSpecRubric';
import ListingSpecNewsNativeStyles from './styles/listingSpecNews';
import { NegativeMobile } from 'site/components/Wrappers';

/**
 * У мобильных баннеров нужно указывать мобильный `siteId` (`begun-auto-pad`).
 */
const MobileAd = bindProps({
  siteId: '496412039',
  height: 250,
})(Ad);

const ListingSpecProps = bindProps({
  name: 'Listing Spec (COM)',
  lazy: true,
  blockId: '496503367',
});

export const TopBanner = bindProps({
  name: 'TopBanner',
  blockId: '496421361',
})(MobileAd);

export const Fullscreen = bindProps({
  name: 'Fullscreen',
  height: null,
  blockId: '496497503',
})(MobileAd);

export const Rich = bindProps({
  name: 'Rich',
  height: null,
  blockId: '496498149',
})(MobileAd);

export const Listing1 = bindProps({
  name: 'Listing1 (COM)',
  lazy: true,
  blockId: '496424537',
})(nest(NegativeMobile, MobileAd));

export const Listing2 = bindProps({
  name: 'Listing2 (COM)',
  lazy: true,
  blockId: '496447547',
})(nest(NegativeMobile, MobileAd));

export const Listing3 = bindProps({
  name: 'Listing3 (COM)',
  lazy: true,
  blockId: '496449227',
})(nest(NegativeMobile, MobileAd));

export const Listing4 = bindProps({
  name: 'Listing4 (COM)',
  lazy: true,
  blockId: '496452229',
})(nest(NegativeMobile, MobileAd));

export const ListingSpecNews = compose(
  ListingSpecProps,
  ListingSpecNewsNativeStyles,
)(MobileAd);

export const ListingSpecSearch = compose(
  ListingSpecProps,
  ListingSpecSearchNativeStyles,
)(MobileAd);

export const ListingSpecRubric = compose(
  ListingSpecProps,
  ListingSpecRubricNativeStyles,
)(MobileAd);

export const Footer = bindProps({
  name: 'Footer (COM)',
  lazy: true,
  blockId: '496494001',
})(MobileAd);

export const Content1 = bindProps({
  name: 'Content1 (COM)',
  lazy: true,
  blockId: '496475817',
})(nest(NegativeMobile, MobileAd));

export const Content2 = bindProps({
  name: 'Content2 (COM)',
  lazy: true,
  blockId: '496475845',
})(nest(NegativeMobile, MobileAd));

export const Content3 = bindProps({
  name: 'Content3 (COM)',
  lazy: true,
  blockId: '496493903',
})(nest(NegativeMobile, MobileAd));

export const Content4 = bindProps({
  name: 'Content4 (COM)',
  lazy: true,
  blockId: '496493931',
})(nest(NegativeMobile, MobileAd));

export const ContentSpec = compose(
  bindProps({
    name: 'Content Spec (COM)',
    lazy: true,
    blockId: '496504121',
  }),
  card3Type2Styles,
)(nest(NegativeMobile, MobileAd));

export const InPage = bindProps({
  name: 'InPage',
  height: 200,
  blockId: '496493945',
})(nest(NegativeMobile, MobileAd));

export const Sponsored = compose(
  bindProps({
    name: 'Sponsored (COM)',
    height: null,
    blockId: '496501363',
  }),
  sponsoredStyles,
)(MobileAd);

export const InGallery = bindProps({
  name: 'InGallery',
  blockId: '579389988',
  disableAutoInit: true,
  height: null,
})(MobileAd);
