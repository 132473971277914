const partners = [
  {
    link: 'http://www.livejournal.com/',
    logo: require('./images/livejournal.jpg'),
    width: 200,
    height: 64,
  },
  {
    link: 'http://www.gazeta.ru/',
    logo: require('./images/gazeta.png'),
    width: 200,
    height: 38,
  },
  {
    link: 'http://www.perinatalmedcenter.ru/',
    logo: require('./images/perinatalmedcenter.png'),
    width: 200,
    height: 53,
  },
  {
    link: 'http://clever-media.ru/',
    logo: require('./images/clever-media.png'),
    width: 72,
    height: 82,
  },
  {
    link: 'http://www.park-gorkogo.com/',
    logo: require('./images/park-gorkogo.png'),
    width: 138,
    height: 82,
  },
  {
    link: 'https://www.chitai-gorod.ru/',
    logo: require('./images/chitai-gorod.ru.jpg'),
    width: 200,
    height: 98,
  },
  {
    link: 'https://foxford.ru/',
    logo: require('./images/foxford.ru.jpg'),
    width: 200,
    height: 112,
  },
  {
    link: 'http://macarun.info/',
    logo: require('./images/macarun.info.png'),
    width: 112,
    height: 112,
  },
  {
    link: 'http://neirokid.ru/',
    logo: require('./images/neirokid.ru.jpg'),
    width: 103,
    height: 112,
  },
  {
    link: 'http://psychol.ru/',
    logo: require('./images/psychol.ru.jpg'),
    width: 200,
    height: 62,
  },
  {
    link: 'http://sprr.net/',
    logo: require('./images/sprr.net.jpg'),
    width: 110,
    height: 112,
  },
  {
    link: 'http://tutta.tv/',
    logo: require('./images/tutta.tv.jpg'),
    width: 200,
    height: 77,
  },
  {
    link: 'http://umnazia.ru/',
    logo: require('./images/umnazia.ru.png'),
    width: 125,
    height: 112,
  },
  {
    link: 'http://vertclinic.ru/',
    logo: require('./images/vertclinic.ru.png'),
    width: 171,
    height: 121,
  },
  {
    link: 'https://eksmo.ru/',
    logo: require('./images/eksmo.ru.jpg'),
    width: 53,
    height: 82,
  },
  {
    link: 'http://www.logoakademia.ru/',
    logo: require('./images/logoakademia.ru.jpg'),
    width: 198,
    height: 112,
  },
  {
    link: 'https://www.mann-ivanov-ferber.ru/',
    logo: require('./images/mann-ivanov-ferber.ru.jpg'),
    width: 115,
    height: 112,
  },
];

export default partners.map((item, index) => {
  return {
    id: index,
    ...item,
  };
});
