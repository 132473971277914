import PropTypes from 'prop-types';
import CaptionCredits from 'core/components/CaptionCredits';
import Image from 'core/components/Image';
import resolveRelationships from 'core/utils/relationships';

import styles from './index.styl';

const relationships = resolveRelationships(
  ['image'],
  {},
  { image: { versions: {} } },
);

export default function TopicHeaderImage({ content }) {
  const {
    image: {
      versions,
      caption,
      credits,
      credits_url: creditsUrl,
    },
  } = relationships(content);

  const {
    attributes: {
      is_premium: isPremium,
      headline,
    },
  } = content;

  const altAndTitle = caption || headline;

  return (
    <>
      <div className={styles.image}>
        <Image
          versions={versions}
          widthFromSrc
          maxWidth={isPremium ? 1280 : 860}
          maxHeight={isPremium ? 650 : 600}
          placement='contain'
          useSchema
          alt={altAndTitle}
          title={altAndTitle}
        />
      </div>
      <CaptionCredits
        caption={caption}
        credits={credits}
        creditsUrl={creditsUrl}
      />
    </>
  );
}

TopicHeaderImage.propTypes = {
  content: PropTypes.object,
};
