export default {
  1: { showComments: true },
  2: {
    rowReverse: true,
    withBackground: true,
    aspectRatio: 1,
    desktopImageMaxWidth: 100,
  },
  3: {
    rowReverse: true,
    withBackground: true,
    aspectRatio: 16 / 9,
    smallTitle: true,
  },
};
