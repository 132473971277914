import PropTypes from 'prop-types';

import Drum from 'core/components/Drum';

import Title5 from 'site/components/Title5';

import { IndentWrap } from 'site/components/Wrappers';

import CardExpert from 'site/cards/CardExpert';


const link = '/label/mneniye-ekspertov';

export default function MainExperts({ content }) {
  if (!content.length) return null;

  return (
    <div>
      <Title5 line link={link}>Мнение экспертов</Title5>
      <IndentWrap bottom={20} />
      <Drum
        content={content}
        card={CardExpert}
        spaceBetween={20}
        itemWidth={260}
        itemWidthMobile={260}
        link={link}
      />
    </div>
  );
}

MainExperts.propTypes = {
  content: PropTypes.array,
};
