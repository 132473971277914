import color from 'core/libs/color';


const colors = {
  layout: '#fff',
  content: '#fff',
  card: '#fff',
  active1: '#fcc11b',
  active2: '#0083F6',
  link: '#1c8af0',
  accent: '#efe5d4',
  error: '#ff3e56',
  primary: '#262626',
  input: '#fff',
  divider: '#E5E5E5',
  gallery: '#32292f',
  spammerBackground: '#f8f8f8',
  get hint() {
    return color(this.primary).alpha(0.5).string();
  },
  get placeholder() {
    return color(this.primary).alpha(0.2).string();
  },
  theme: '#9620C2',
};

export default {
  colors,
};
