import PopularCore from 'core/components/Popular';

import { filterRequiredParams } from 'core/utils/api';

import Card3 from 'site/cards/Card3';

const fields = filterRequiredParams([Card3], 'fields');

export default function Popular({ ...feedProps }) {
  return (
    <PopularCore
      {...feedProps}
      title='Популярное'
      card={Card3}
      sort='-views_count'
      limit={4}
      fields={fields}
    />
  );
}
