import PropTypes from 'prop-types';

import bindProps from 'core/components/bindProps';

import replaceCardWithAd from 'core/components/Ad/hoc/replaceCardWithAd';
import Mjolnir from 'core/components/Mjolnir';

import Title5 from 'site/components/Title5';

import { IndentWrap } from 'site/components/Wrappers';

import Card2 from 'site/cards/Card2';
import CardPhoto from 'site/cards/CardPhoto';

import { Native2 } from 'site/components/Ads/desktop';


const HeroCard = bindProps({ imageVersion: 'main' })(CardPhoto);

export default function MainPhoto({ isDesktop, content }) {
  if (!content.length) return null;

  return (
    <div>
      <Title5 line>Фотоальбомы</Title5>
      <IndentWrap bottom={20} />
      <Mjolnir
        heroCard={HeroCard}
        card={Card2}
        interitemSpacing={20}
        content={content}
        cardsReplacement={{
          3: replaceCardWithAd(Native2, isDesktop),
        }}
      />
    </div>
  );
}

MainPhoto.propTypes = {
  isDesktop: PropTypes.bool,
  content: PropTypes.array,
};
