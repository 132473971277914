import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';


function TextGray({ children, theme }) {
  return (
    <span className='text-gray'>
      <style jsx>{`
      .text-gray
        font 14px/1.3 ${theme.fonts.text}
        color #9a9ea1
      `}
      </style>
      {children}
    </span>
  );
}

TextGray.propTypes = {
  theme: PropTypes.object,
};

export default withTheme(TextGray);
