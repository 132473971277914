import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './index.styl';

const MAX_WIDTH = 270;
const MAX_HEIGHT = 168.75;

const GlobalStyles = (props) => {
  const { theme } = props;

  return (
    <div className={cx(styles['adv-native'])}>
      <style global jsx>{`
        .adv-native
          .ad_native_img
            max-width ${MAX_WIDTH}px
            max-height ${MAX_HEIGHT}px
            background-color ${theme.colors.placeholder}
            &:after
              padding-top ${100 / (MAX_WIDTH / MAX_HEIGHT) + '%'}
            &:before
              transition opacity ${theme.animations.hover}

          .ad_label__text
            border 1px solid ${theme.colors.hint}
            color ${theme.colors.hint}
          .adv_info__text
            font 14px/1.2 ${theme.fonts.display}

          .ad_native_title
            transition color ${theme.animations.hover}

          a
            color ${theme.colors.primary}
            font-family ${theme.fonts.text}
            &:hover
              .ad_native_title
                color ${theme.colors.active1}
              .ad_native_img:before
                background-color ${theme.colors.placeholder}
                opacity 1
      `}</style>
    </div>
  );
};

GlobalStyles.propTypes = {
  theme: PropTypes.object,
};

export default GlobalStyles;
