import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { Helmet } from 'core/libs/helmet';

import {
  rawRubricFetcher,
  rawTopicsFetcher,
} from 'core/fetchers/rubric';

import resolve from 'core/resolver/resolve';

import withPageHocs from 'core/components/withPageHocs';

import Rubric from 'core/components/Rubric';
import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent } from 'core/components/Wrappers';

import { filterRequiredParams } from 'core/utils/api';

import InnerSideColumn from 'site/components/InnerSideColumn';
import RubricTopics from 'site/components/RubricTopics';
import RubricTitle from 'site/components/RubricTitle';
import Boroda from 'site/components/Boroda';
import Card1 from 'site/cards/Card1';
import Card4 from 'site/cards/Card4';

import FooterAds from 'site/components/Ads/FooterAds';


const limit = 18;
const fields = filterRequiredParams([Card1, Card4], 'fields');

function RubricPage(props) {
  const {
    match: {
      params: {
        level_1: rubricSlug,
      },
    },
    rawRubric,
    rawTopics,
  } = props;

  return (
    <PageIndent>
      <ColumnLayout rightColumn={<InnerSideColumn rubricSlug={rubricSlug} />}>
        <Rubric
          rawRubric={rawRubric}
          rawTopics={rawTopics}
          limit={limit}
          titleComponent={RubricTitle}
        >
          {({ topics, rawRubric: rubric }) => {
            const slug = rubric?.data?.attributes?.slug;
            let customMeta = null;

            if (slug === 'novyy-god') {
              const year = new Date().getFullYear() + 1;
              customMeta = (
                <Helmet>
                  <title>{`Новый год ${year} — все статьи и новости`}</title>
                  <meta name='description' content={`Новый год ${year} - подготовка к празднику: как украсить елку и выбрать подарки. В каком наряде лучше встречать Новый год и что приготовить.`} />
                </Helmet>
              );
            }

            return (
              <Fragment>
                {customMeta}
                <RubricTopics topics={topics} />
              </Fragment>
            );
          }}
        </Rubric>
        <Boroda showRecommender showPopular />
      </ColumnLayout>
      <FooterAds contextCount={3} />
    </PageIndent>
  );
}

RubricPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      level_1: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  rawRubric: PropTypes.object,
  rawTopics: PropTypes.object,
};

const dataProvider = resolve({
  rawRubric: rawRubricFetcher(),
  rawTopics: rawTopicsFetcher({
    limit,
    fields,
    include: 'image',
  }),
});

export default withPageHocs(dataProvider)(RubricPage);
