import PropTypes from 'prop-types';

import TitleCardL from 'site/components/TitleCardL';
import McDonaldsLabel from 'site/components/mcdonalds/McDonaldsLabel';

import { DOBRYE_DELA_SLUG } from 'site/constants';

import styles from './index.styl';

function RubricTitle(props) {
  const {
    title,
    rawRubric,
  } = props;

  const slug = rawRubric?.data?.attributes?.slug;

  return (
    <div className={styles.header}>
      <TitleCardL tag='h1'>{title}</TitleCardL>
      {slug === DOBRYE_DELA_SLUG && (
        <McDonaldsLabel />
      )}
    </div>
  );
}

RubricTitle.propTypes = {
  title: PropTypes.string,
  rawRubric: PropTypes.object,
};

export default RubricTitle;
