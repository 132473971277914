import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';

import GlobalStyles from '../global';
import './index.styl';

const Card3Type2Styles = ({ theme }) => {
  const {
    block: {
      background,
      padding,
    },
    title: {
      font,
      color,
      textTransform,
      letterSpacing,
      marginBottom,
      textAlign,
    },
  } = theme.controls.vikontReadMore;

  return (
    <Fragment>
      <style global jsx>{`
        .adv-native_card-3
          .ad_native_wrapper
            padding ${padding}
            background ${background}
          
          .ad_native_content-wrapper
            background ${theme.colors.layout}
          
          .adv_info__text
            font ${font}
            color ${color}
            text-transform ${textTransform}
            letter-spacing ${letterSpacing}
            margin-bottom ${marginBottom}
            text-align ${textAlign}

          .ad_native_title
            font 16px/1.2 ${theme.fonts.text}
            :global(.mobile) &
              font 14px/1.3 ${theme.fonts.text}

          .ad_native_desc
            font italic 14px/1.2 ${theme.fonts.text}
      `}</style>
      <style global jsx>{`
        .adv-native_card-3
          .ad_native_content-wrapper
            padding 8px

          .ad_native_img
            background-color transparent
      `}</style>
    </Fragment>
  );
};

Card3Type2Styles.propTypes = {
  theme: PropTypes.object,
};

export default function card3Type2(Component) {
  return withTheme(props => (
    <div className='adv-native adv-native_card-3'>
      <GlobalStyles {...props} />
      <Card3Type2Styles {...props} />
      <Component {...props} />
    </div>
  ));
}
