import Exchange from 'core/components/Exchange';

export function PartnersVertical() {
  return (
    <Exchange
      pageType='desktop_all'
      blockType='vertical'
    />
  );
}
export function PartnersHorizontal() {
  return (
    <Exchange
      pageType='desktop_mobile_topics'
      blockType='horizontal'
    />
  );
}
