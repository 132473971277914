import PropTypes from 'prop-types';

import bindProps from 'core/components/bindProps';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import Drum from 'core/components/Drum';
import Feed from 'core/components/Feed';

import Recommender from 'core/components/Recommender';

import Title5 from 'site/components/Title5';
import Card2 from 'site/cards/Card2';
import Card3 from 'site/cards/Card3';

const Card2Type4 = bindProps({ type: 4 })(Card2);

function SiteRecommender({ isMobile }) {
  return (
    <Recommender
      blockId={isMobile ? '38811dbffaba41a1907cce5daf245c32' : 'b7fcec616cf944b8990a51586dc7d7f3'}
      renderer={isMobile ? Feed : Drum}
      title='Выбор читателей'
      headerComponent={Title5}
      interitemSpacing={15}
      card={isMobile ? Card3 : Card2Type4}
      columns={3}
      grid
      limit={isMobile ? 3 : 6}
    />
  );
}

SiteRecommender.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(SiteRecommender);
