import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { rawTopicsFetcher } from 'core/fetchers/listPage';

import resolve from 'core/resolver/resolve';

import withPageHocs from 'core/components/withPageHocs';
import ListPage from 'core/components/ListPage';
import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent } from 'core/components/Wrappers';

import { filterRequiredParams } from 'core/utils/api';

import { IndentWrap } from 'site/components/Wrappers';
import InnerSideColumn from 'site/components/InnerSideColumn';
import RubricTopics from 'site/components/RubricTopics';
import RubricTitle from 'site/components/RubricTitle';
import Boroda from 'site/components/Boroda';
import Card1 from 'site/cards/Card1';
import Card4 from 'site/cards/Card4';

import FooterAds from 'site/components/Ads/FooterAds';


const title = 'Новости';
const fields = filterRequiredParams([Card1, Card4], 'fields');

function NewsPage({ rawData }) {
  return (
    <PageIndent>
      <ColumnLayout rightColumn={<InnerSideColumn />}>
        <ListPage rawData={rawData} title={title}>
          {({ content }) => (
            <Fragment>
              <RubricTitle title={title} />
              <IndentWrap bottom={40} />
              <RubricTopics topics={content} />
            </Fragment>
          )}
        </ListPage>
        <Boroda showRecommender showPopular />
      </ColumnLayout>
      <FooterAds contextCount={3} />
    </PageIndent>
  );
}

NewsPage.propTypes = {
  rawData: PropTypes.object,
};

const dataProvider =  resolve({
  rawData: rawTopicsFetcher({
    limit: 18,
    include: 'image',
    fields,
    topic_type: 'news',
  }),
});

export default withPageHocs(dataProvider)(NewsPage);
