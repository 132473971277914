import React from 'react';

import withTheme from 'core/components/theme';

import GlobalStyles from '../global';
import './index.styl';

export default function listingSpecNewsStyles(Component) {
  return withTheme(props => (
    <div className='adv-native adv-listing-spec-news'>
      <GlobalStyles {...props} />
      <Component {...props} />
    </div>
  ));
}
