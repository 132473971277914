import React from 'react';
import PropTypes from 'prop-types';

export const VERTICAL = 40;
export const VERTICAL_MOBILE = 20;
export const BLOCK_HORIZONTAL = 54;
export const BLOCK_MOBILE_HORIZONTAL = 20;

export const CONTENT_VERTICAL = 20;

export function VerticalIndent({ top }) {
  return (
    <div style={{ marginTop: top || VERTICAL }} />
  );
}

VerticalIndent.propTypes = {
  top: PropTypes.number,
};

export function ContentVerticalIndent() {
  return (
    <div style={{ marginTop: CONTENT_VERTICAL }} />
  );
}
