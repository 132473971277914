import PropTypes from 'prop-types';
import { withRouter } from 'core/libs/router';
import queryString from 'core/libs/query-string';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import TopicHeaderTexts from 'core/components/TopicHeaderTexts';
import TopicTopline from 'core/components/TopicTopline';

import SocialShare from 'site/components/SocialShare';
import { IndentWrap } from 'site/components/Wrappers';
import { ContentVerticalIndent } from 'site/components/Indents';
import Sponsored from 'site/components/Ads/Sponsored';

import TopicHeaderMedia from './TopicHeaderMedia';

function TopicHeader(props) {
  const {
    content,
    location: {
      search,
    },
    comments,
    infinityIndex,
    isDesktop,
  } = props;

  const {
    attributes: {
      link,
      is_premium: isPremium,
      is_comments_disabled: isCommentsDisabled,
    },
  } = content;

  /**
   * Если это премиальная статья, то ничего не выводим
   * Вся информация выводится в TopicHeaderPremium
   */
  if (isPremium) return null;

  const page = infinityIndex > 0
    ? 1
    : +queryString.parse(search).page || 1;

  return (
    <div>
      <IndentWrap bottom={24}>
        {!isDesktop && <ContentVerticalIndent />}
        <TopicTopline />
        <IndentWrap bottom={isDesktop ? 20 : 16} />
        <TopicHeaderTexts />
        <Sponsored />
        {isDesktop && (
          <SocialShare
            xid={link}
            isCommentsDisabled={isCommentsDisabled}
          />
        )}
      </IndentWrap>
      {page === 1 && !comments && (
        <IndentWrap bottom={isDesktop ? 36 : 24}>
          <TopicHeaderMedia content={content} />
        </IndentWrap>
      )}
    </div>
  );
}

TopicHeader.propTypes = {
  type: PropTypes.string,
  content: modelPropTypes(topicAttributes).isRequired,
  location: PropTypes.object.isRequired,
  comments: PropTypes.bool,
  infinityIndex: PropTypes.number,
  isDesktop: PropTypes.bool,
};

export default withRouter(withBreakpoint(TopicHeader));
