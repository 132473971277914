import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent, Indent } from 'core/components/Wrappers';
import Page from 'core/components/Page';
import Feed from 'core/components/Feed';

import TitleCardL from 'site/components/TitleCardL';

import {
  Ad240x400,
} from 'site/components/Ads/desktop';

import CardPartner from 'site/cards/CardPartner';
import data from './data';

const title = 'Наши партнеры';

export default function Partners() {
  return (
    <Page
      title={title}
      description='Рекламные партнеры на Летидоре'
    >
      <PageIndent>
        <ColumnLayout rightColumn={<Ad240x400 />}>
          <TitleCardL tag='h1'>{title}</TitleCardL>
          <Indent bottom={40} top={40}>
            <Feed
              card={CardPartner}
              content={data}
              columns={3}
              interitemSpacing={40}
              grid
            />
          </Indent>
        </ColumnLayout>
      </PageIndent>
    </Page>
  );
}
