import PropTypes from 'prop-types';

import { get } from 'core/libs/lodash';
import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import resolveRelationships from 'core/utils/relationships';

import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent } from 'core/components/Wrappers';

import InnerSideColumn from 'site/components/InnerSideColumn';

import TopicHeaderPremium from 'site/pages/topic/TopicHeaderPremium';

const relationships = resolveRelationships(['rubric'], {}, {
  rubric: {},
});

function TopicWrapper({ children, content, infinityIndex }) {
  const isPremium = get(content, 'attributes.is_premium');
  const slug = relationships(content).rubric.slug;

  return (
    <PageIndent>
      {isPremium && <TopicHeaderPremium content={content} infinityIndex={infinityIndex} />}
      <ColumnLayout
        rightColumn={(
          <InnerSideColumn
            show240x200
            rubricSlug={slug}
          />
        )}
      >
        {children}
      </ColumnLayout>
    </PageIndent>
  );
}

TopicWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  infinityIndex: PropTypes.number,
  content: modelPropTypes(topicAttributes).isRequired,
};

export default TopicWrapper;
