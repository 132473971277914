import cx from 'classnames';
import { Mobile, Desktop } from 'core/components/breakpoint';
import MarkdownWrapper from 'core/components/MarkdownWrapper';
import Button from 'core/components/Button';
import Link from 'core/components/Link';

import LetidorLogo from 'site/components/Logo';
import McdonaldsLogo from 'site/icons/McdonaldsLogo';
import BorderTopDesktop from './images/BorderTopDesktop';
import BorderLeftDesktop from './images/BorderLeftDesktop';
import BorderBottomDesktop from './images/BorderBottomDesktop';
import BorderRightDesktop from './images/BorderRightDesktop';
import BorderTopMobile from './images/BorderTopMobile';
import BorderLeftMobile from './images/BorderLeftMobile';
import BorderBottomMobile from './images/BorderBottomMobile';
import BorderRightMobile from './images/BorderRightMobile';

import styles from './index.styl';

export default function McDonaldsWidget(props) {
  return (
    <div className={styles.widget}>
      <div className={styles.header}>
        <LetidorLogo width='67' height='18' />
        <div className={styles.houseLogo} />
        <McdonaldsLogo width='26' height='23' />
      </div>
      <MarkdownWrapper className={styles.content}>
        {props.children}
      </MarkdownWrapper>
      <div className={styles.link}>
        <Link to='https://rmhc.ru/'>
          <Button minWidth={180}>Помочь</Button>
        </Link>
      </div>
      <Mobile>
        <BorderTopMobile className={cx(styles.border, styles._top)} />
        <BorderLeftMobile className={cx(styles.border, styles._left)} />
        <BorderBottomMobile className={cx(styles.border, styles._bottom)} />
        <BorderRightMobile className={cx(styles.border, styles._right)} />
      </Mobile>
      <Desktop>
        <BorderTopDesktop className={cx(styles.border, styles._top)} />
        <BorderLeftDesktop className={cx(styles.border, styles._left)} />
        <BorderBottomDesktop className={cx(styles.border, styles._bottom)} />
        <BorderRightDesktop className={cx(styles.border, styles._right)} />
      </Desktop>
    </div>
  );
}
