import PropTypes from 'prop-types';

import resolve from 'core/resolver/resolve';

import {
  authorFetcher,
  topicsFetcher,
} from 'core/fetchers/authorPage';

import bindProps from 'core/components/bindProps';
import withPageHocs from 'core/components/withPageHocs';

import AuthorPage from 'core/components/AuthorPage';
import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent } from 'core/components/Wrappers';

import { filterRequiredParams } from 'core/utils/api';

import InnerSideColumn from 'site/components/InnerSideColumn';
import Boroda from 'site/components/Boroda';
import FooterAds from 'site/components/Ads/FooterAds';

import Card4 from 'site/cards/Card4';


const Card4Type1 = bindProps({ type: 1 })(Card4);

const fields = filterRequiredParams([Card4Type1], 'fields');

function Author({ author, topics }) {
  return (
    <PageIndent>
      <ColumnLayout rightColumn={<InnerSideColumn />}>
        <AuthorPage
          author={author}
          topics={topics}
          card={Card4Type1}
          interitemSpacing={30}
        />
        <Boroda showRecommender showPopular />
      </ColumnLayout>
      <FooterAds />
    </PageIndent>
  );
}

Author.propTypes = {
  topics: PropTypes.object,
  author: PropTypes.object,
};

const dataProvider = resolve({
  author: authorFetcher(),
  topics: topicsFetcher({
    limit: 10,
    include: 'image',
    fields,
  }),
});

export default withPageHocs(dataProvider)(Author);
