import PropTypes from 'prop-types';
import { get } from 'core/libs/lodash';

import IncutText from 'core/components/Incut';
import MarkdownWrapper from 'core/components/MarkdownWrapper';

import styles from './index.styl';

export default function NumberSnipet(props) {
  const {
    block,
    parentProps: {
      theme,
    },
  } = props;

  const body = get(block, 'attributes.body');
  const value = get(block, 'attributes.value');
  const unit = get(block, 'attributes.unit');

  return (
    <div className={styles.incut}>
      <style jsx>{`
        .${styles.icon}
          color ${theme.colors.active1}
          font-family ${theme.fonts.text}
      `}
      </style>
      <div className={styles.icon}>
        {value}{unit}
      </div>
      <IncutText>
        <MarkdownWrapper>
          {body}
        </MarkdownWrapper>
      </IncutText>
    </div>
  );
}

NumberSnipet.propTypes = {
  block: PropTypes.object,
  parentProps: PropTypes.object,
};
