import React from 'react';
import PropTypes from 'prop-types';

import cardHOC from 'core/components/card';
import Link from 'core/components/Link';
import Image from 'core/components/SmartImage';

import relationshipsPropTypes, {
  imagePropTypes,
} from 'core/utils/prop-types/relationships';
import resolveRelationships from 'core/utils/relationships';
import cutDown from 'core/utils/cut-down';

import TitleCardL from 'site/components/TitleCardL';

import { imageVersionExists } from 'site/utils';
import { MOBILE_MAX_WIDTH } from 'site/constants';
import styles from './index.styl';

const requiredPayloadFields = [
  'link',
  'headline',
  'list_headline',
  'announce',
  'topic_type',
];

const relationships = resolveRelationships(['image', 'rubric'], {}, {
  image: {
    versions: {},
  },
  rubric: {},
});

function CardPhoto(props) {
  const {
    content,
    theme,
    imageVersion,
    isMobile,
  } = props;

  const {
    attributes: {
      link,
      headline,
      list_headline: listHeadline,
      announce,
      topic_type: topicType,
    },
  } = content;

  const {
    image: {
      versions: {
        [imageVersion]: preCover,
        original: originalCover,
        thumbnail: previewCover,
      },
    },
  } = relationships(content);

  const {
    icons: {
      Photo: Icon,
    },
  } = theme;

  const cover = imageVersionExists(preCover) ? preCover : originalCover;
  return (
    <div className={styles.card}>
      <style jsx>{`
        .${styles.image}
          &:after
            transition opacity ${theme.animations.hover}
          :global(.mobile) &
            max-width ${MOBILE_MAX_WIDTH}px
        .${styles.announce}
          font 500 18px/1.22 ${theme.fonts.text}
          color ${theme.colors.primary}
          :global(.mobile) &
            font 500 18px/1.4 ${theme.fonts.text}
      `}</style>
      <Link to={link} type='secondary' >
        <TitleCardL>{listHeadline || headline}</TitleCardL>
        <div className={styles.announce}>
          {cutDown(announce, 120, true)}
        </div>
        <div className={styles.image}>
          <Image
            src={cover}
            previewSrc={previewCover}
            maxWidth={isMobile ? MOBILE_MAX_WIDTH : 840}
            aspectRatio={1.6}
            position='top'
          />
          {topicType === 'gallery' && (
            <div className={styles.icon}>
              <Icon height={50} width={50} />
            </div>
          )}
        </div>
      </Link>
    </div>
  );
}

CardPhoto.propTypes = {
  /** Переобложка изображения, которая будет использоваться в карточке вместо `original` */
  imageVersion: PropTypes.string,
  content: relationshipsPropTypes({
    image: imagePropTypes(),
  }),
  theme: PropTypes.object,
  isMobile: PropTypes.bool,
};

const Card = cardHOC(CardPhoto);

Card.requiredPayloadFields = requiredPayloadFields;

export default Card;
