import { Route } from 'core/libs/router';
import { Helmet } from 'core/libs/helmet';

import configResolver from './shared-app-config';

import App from 'core/components/App';
import { Mobile, Desktop } from 'core/components/breakpoint';
import BaseRoutes from 'core/components/BaseRoutes';
import bindPropsHOC from 'core/components/bindProps';
import LegalPage from 'core/components/LegalPage';
import RulesRecommenders from 'core/components/RulesRecommenders';

import Layout from 'site/components/Layout';

import {
  Shtorka,
  Fullscreen as DesktopFullscreen,
} from 'site/components/Ads/desktop';

import {
  Rich,
  Fullscreen as MobileFullscreen,
} from 'site/components/Ads/mobile';

import Main from 'site/pages/main';
import Topic from 'site/pages/topic';
import Search from 'site/pages/search';
import Rubric from 'site/pages/rubric';
import Tag from 'site/pages/tag';
import Partners from 'site/pages/partners';
import News from 'site/pages/news';
import About from 'site/pages/about';
import Articles from 'site/pages/articles';
import Author from 'site/pages/author';
import Comments from 'site/pages/comments';
import Authors from 'site/pages/authors';
import Profile from 'site/pages/profile';

function Site() {
  /**
   * Конфиг приложения нужно резолвить на каждую перерисовку чтоб при SSR
   * правильно резолвились переменные окружения.
   */
  const config = configResolver();

  return (
    <>
      <Helmet>
        <script src='https://ad.mail.ru/static/sync-loader.js' />
      </Helmet>
      <App {...config}>
        <Layout>
          <BaseRoutes
            main={Main}
            topic={Topic}
            rubrics={Rubric}
            about={About}
            search={Search}
            tags={Tag}
            author={Author}
            comments={Comments}
            authors={Authors}
            profile={Profile}
            legal={LegalPage}
            rulesRecommenders={bindPropsHOC({ mail: 'info@letidor.ru' })(RulesRecommenders)}
          >
            <Route
              path='/partners'
              component={Partners}
              exact
            />
            <Route
              path='/news'
              component={News}
              exact
            />
            <Route
              path='/articles'
              component={Articles}
              exact
            />
            <Route
              path='/age/:level_2([a-z0-9\-_]+)'
              component={Tag}
              exact
            />
          </BaseRoutes>
        </Layout>
        <Desktop>
          <Shtorka />
          <DesktopFullscreen />
        </Desktop>
        <Mobile>
          <Rich />
          <MobileFullscreen />
        </Mobile>
      </App>
    </>
  );
}

export default Site;
